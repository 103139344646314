
















































































































































































import {
  Component, Vue, Prop, Emit, Watch,
} from 'vue-property-decorator';
// eslint-disable-next-line import/no-cycle
import PromotionService from '@/services/PromotionService';

const promotionService = new PromotionService();

@Component
export default class JoinPromotionPage extends Vue {
  public promotions: any = [];

  async joinPromotion(promotionId: any) {
    try {
      await promotionService.acceptPromotion({ promotionId });
      this.$store.commit('Snackbar/showNotification', { message: 'รับโปรโมชั่นสำเร็จ', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  updated() {
    const { to } = this.$route.query;
    if (to && typeof to === 'string') {
      const element = this.$refs[`PROMOTION-${to}`];
      if (element && element[0]) {
        this.$vuetify.goTo(element[0]);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get lineUrl() {
    return process.env.VUE_APP_LINE;
  }

  async created() {
    const { data } = await promotionService.getAvailablePromotions();
    this.promotions = [...data];
  }
}
